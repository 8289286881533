import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/seo"
import componentStyles from "./cloud.module.scss"
import CloudServicesIllustration from "../../assets/images/cloud-illustration.svg"
import CloudServicesIllustration2 from "../../assets/images/cloud-illustration-2.svg"
import Icon1 from "../../assets/images/Economy.svg"
import Icon2 from "../../assets/images/Comparing.svg"
import Icon3 from "../../assets/images/Map.svg"
import Icon4 from "../../assets/images/Options.svg"
import AWSPartnerImage from "../../../static/images/aws-partner.png"
import AWSPartnerImageWEBP from "../../../static/images/aws-partner.webp"
import GoogleCloudLogo from "../../../static/images/cloud-logo.svg"
import MicrosoftAzureLogo from "../../../static/images/microsoft.svg"
import Button from "../../components/Button/Button"
import { projectsList, servicesList } from "../../hooks"
import { useProjectsCardsThumbnails } from "../../hooks/useProjectsCardsThumbnails"
import services from "../services.module.scss"
import PresentationForm from "../../components/PresentationForm/PresentationForm"

const numberedListItems = [
  "services.cloud.page.first-block.numbered-list.first-text",
  "services.cloud.page.first-block.numbered-list.second-text",
  "services.cloud.page.first-block.numbered-list.third-text",
]
const firstCubeListItems = [
  "services.cloud.page.first-block.cube-list.first-text",
  "services.cloud.page.first-block.cube-list.second-text",
  "services.cloud.page.first-block.cube-list.third-text",
  "services.cloud.page.first-block.cube-list.forth-text",
]

const secondListItems = [
  {
    icon: Icon1,
    textPath: "services.cloud.page.second-block.list.first-text",
  },
  {
    icon: Icon2,
    textPath: "services.cloud.page.second-block.list.second-text",
  },
  {
    icon: Icon3,
    textPath: "services.cloud.page.second-block.list.third-text",
  },
  {
    icon: Icon4,
    textPath: "services.cloud.page.second-block.list.forth-text",
  },
]

const secondCubeListItems = [
  "services.cloud.page.third-block.list.first-text",
  "services.cloud.page.third-block.list.second-text",
  "services.cloud.page.third-block.list.third-text",
  "services.cloud.page.third-block.list.forth-text",
]

const Cloud: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()
  const projects = projectsList
  const projectsThumbnails = useProjectsCardsThumbnails()
  const serviceList = servicesList
  const bannerClasses = classNames(componentStyles.banner)
  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    "container",
    "grid-2"
  )
  const cloudClasses = classNames(componentStyles.cloud, "container")
  const numberedListClasses = classNames(
    componentStyles.cloudBlockFirstNumberedList
  )
  const secondListClasses = classNames(
    componentStyles.cloudSecondBlockList,
    "grid-4"
  )

  const thirdBlockCubeItemClasses = classNames(
    componentStyles.cubeItem,
    componentStyles.cloudThirdBlockCubeItem
  )

  const projectsClasses = classNames(componentStyles.cloudProjects, "container")
  const projectsListClasses = classNames(
    componentStyles.cloudProjectsList,
    "grid-2"
  )
  const projectsTitleClasses = classNames(
    componentStyles.cloudProjectsTitle,
    "large-section-title"
  )
  const blockInServices = classNames(services.blockInServices, "container")
  const blockInServicesList = classNames(services.blockInServicesList, "grid-3")

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({
          id: "services.cloud.seo.description",
        })}
        title={intl.formatMessage({ id: "services.cloud.seo.title" })}
        pathname={location.pathname}
      />
      <section className={bannerClasses}>
        <div className={bannerContainerClasses}>
          <h1 className={componentStyles.bannerTitle}>
            {intl.formatMessage({
              id: "services.cloud.page.banner.title",
            })}
          </h1>
          <div className={componentStyles.bannerImage}>
            <img
              className={componentStyles.bannerImageDevCube}
              src="/icons/dev-cube.svg"
              alt="Orange graphic cube image"
              width="72"
              height="92"
            />
            <img
              className={componentStyles.bannerImageMainImage}
              src={CloudServicesIllustration}
              alt="Illustration of cloud services and servers"
              width="575"
              height="464"
            />
          </div>
          <div>
            <p className={componentStyles.bannerText}>
              {intl.formatMessage({
                id: "services.cloud.page.banner.paragraph-1",
              })}
            </p>
            <p className={componentStyles.bannerText}>
              {intl.formatMessage({
                id: "services.cloud.page.banner.paragraph-2",
              })}
            </p>
            <PresentationForm/>
          </div>
        </div>
      </section>
      <section className={cloudClasses}>
        <div className={componentStyles.cloudBlock}>
          <div className="grid-2">
            <h4 className={componentStyles.cloudBlockFirstTitle}>
              {intl.formatMessage({
                id: "services.cloud.page.first-block.title",
              })}
            </h4>
            <div className={componentStyles.cloudBlockFirstAwsPartner}>
            <picture>
              <source
                type="image/webp"
                srcSet={AWSPartnerImageWEBP}
              />
              <source
                type="image/png"
                srcSet={AWSPartnerImage}
              />
              <img
                src={AWSPartnerImage}
                alt="Amazon Partner logo"
                width="564"
                height="130"
              />
            </picture>
            </div>
            <ol className={numberedListClasses}>
              {numberedListItems.map(translatePath => (
                <li
                  key={translatePath}
                  className={componentStyles.cloudBlockFirstNumberedListItem}
                >
                  <p
                    className={
                      componentStyles.cloudBlockFirstNumberedListItemText
                    }
                  >
                    {intl.formatMessage({
                      id: translatePath,
                    })}
                  </p>
                </li>
              ))}
            </ol>
            <div>
              <p className={componentStyles.cloudBlockFirstParagraph}>
                {intl.formatMessage({
                  id: "services.cloud.page.first-block.paragraph",
                })}
              </p>
              <ul className={componentStyles.cubeList}>
                {firstCubeListItems.map(textPath => (
                  <li key={textPath} className={componentStyles.cubeItem}>
                    {intl.formatMessage({
                      id: textPath,
                    })}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className={cloudClasses}>
        <div className={componentStyles.cloudSecondBlock}>
          <h4 className={componentStyles.cloudSecondBlockTitle}>
            {intl.formatMessage({
              id: "services.cloud.page.second-block.title",
            })}
          </h4>

          <ul className={secondListClasses}>
            {secondListItems.map(({ icon, textPath }, index) => (
              <li
                key={textPath}
                className={componentStyles.cloudSecondBlockListItem}
              >
                <img
                  className={componentStyles.cloudSecondBlockListIcon}
                  src={icon}
                  alt={`Icon for ${index} item`}
                  width="36"
                  height="36"
                />
                <p className={componentStyles.cloudSecondBlockListText}>
                  {intl.formatMessage({
                    id: textPath,
                  })}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </section>
      <section className={cloudClasses}>
        <div className={componentStyles.cloudThirdBlock}>
          <h4 className={componentStyles.cloudThirdBlockTitle}>
            {intl.formatMessage({
              id: "services.cloud.page.third-block.title",
            })}
          </h4>
          <div className="grid-2">
            <ul className={componentStyles.cubeList}>
              {secondCubeListItems.map(textPath => (
                <li key={textPath} className={thirdBlockCubeItemClasses}>
                  {intl.formatMessage({
                    id: textPath,
                  })}
                </li>
              ))}
            </ul>
            <div className={componentStyles.cloudThirdBlockIllustration}>
              <img
                src={CloudServicesIllustration2}
                alt="Illustration with digital cloud"
                width="423"
                height="673"
              />
            </div>
            <p className={componentStyles.cloudThirdBlockText}>
              {intl.formatMessage({
                id: "services.cloud.page.third-block.paragraph-1",
              })}
            </p>
            <div>
              <p className={componentStyles.cloudThirdBlockSubtitle}>
                {intl.formatMessage({
                  id: "services.cloud.page.third-block.paragraph-2",
                })}
              </p>
              <div className={componentStyles.cloudThirdBlockImageList}>
                <img
                  className={componentStyles.cloudThirdBlockImageItem}
                  src={GoogleCloudLogo}
                  alt="Google Cloud Logo"
                  width="252"
                  height="38"
                />
                <img
                  className={componentStyles.cloudThirdBlockImageItem}
                  src={MicrosoftAzureLogo}
                  alt="Microsoft Azure Logo"
                  width="252"
                  height="26"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={blockInServices}>
        <h2 className={projectsTitleClasses}>
          {intl.formatMessage({ id: "index.services.top-title" })}
        </h2>
        <ul className={blockInServicesList}>
          {serviceList.map(({ title, link }) => {
            const path = "/services/" + link
            if (
              "/" + intl.locale + path !== location.pathname &&
              "/" + intl.locale + path + "/" !== location.pathname
            ) {
              return (
                <li key={title} className={services.blockInServicesListItem}>
                  <h3 className={services.blockInServicesListItemTitle}>
                    {intl.formatMessage({ id: title })}
                  </h3>
                  <Button
                    to={path}
                    className={services.blockInServicesListButton}
                  >
                    {intl.formatMessage({ id: "services.button-title" })}
                  </Button>
                </li>
              )
            }
          })}
        </ul>
      </section>
    </Layout>
  )
}
export default Cloud
